<script setup lang="ts">
    import type { NotificationData } from '~/@types/cms';
    import { AtmLink } from '#components';

    declare interface NotificationProps {
        notification: NotificationData;
    }
    const props = defineProps<NotificationProps>();

    const notificationStore = useNotificationStore();

    const makeColorStyle = (color: string, property: 'color' | 'background' = 'color') => (color ? `${property}: #${color.replace('#', '')}` : '');

    const textColor = computed(() => makeColorStyle(props.notification.data.text_color));
    const bgColor = computed(() => makeColorStyle(props.notification.data.bg_color, 'background'));
    const linksInsideText = computed(() => props.notification.data.link_option === 'links_inside_text');

    const close = () => {
        notificationStore.addClosedNotificationId(props.notification.id);
    };
</script>
<template>
    <div
        class="mol-notification relative z-md flex items-center justify-stretch bg-woom-black"
        :style="bgColor">
        <component
            :is="linksInsideText ? 'div' : AtmLink"
            class="gtm-click-notification w-full"
            :link="notification.data.link"
            :storage="notification.refs.links"
            link-only>
            <atm-container
                :class="[
                    'mol-notification-text line-clamp-2 flex h-12 items-center justify-center text-balance py-2 pl-6 pr-[60px] text-center text-xs text-white lg:px-[68px]',
                    { 'font-mono': notification.data.font_family !== 'sans', 'max-md:h-auto max-md:leading-8': linksInsideText },
                ]"
                :style="textColor"
                tag="span"
                no-margin>
                <renderer-html :content="woomTextFormat(notification.data.text)" />
            </atm-container>
        </component>
        <button
            v-if="notification.data.close_button"
            class="absolute right-6 top-1 h-10 w-10 shrink-0 transition-colors hover:text-woom-red max-lg:right-4"
            :style="textColor"
            @click="close()">
            <woom-icon-close class="mx-auto h-4 w-4 fill-current" />
        </button>
    </div>
</template>
<style scoped>
    .mol-notification-text :deep(a) {
        @apply py-1 underline;
    }
    .mol-notification-text :deep(p) {
        /* Padding prevents focus styles from being clipped by overflow: hidden on line-clamp */
        @apply line-clamp-2 p-2;
    }
</style>
